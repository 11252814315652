import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

export default gql`
    fragment ProgramsSummaryFragment on Program {
        path
        title
        dek
        heroProgramsProjects {
            heroImage {
                ...ImageSummaryFragment
            }
            mobileHeroImage {
                ...ImageSummaryFragment
            }
            logo {
                ...ImageSummaryFragment
            }
        }
    }
    ${ImageSummaryFragment}
`;
